import { Credentials } from '@/entities/auth/Credentials'
import { globalconst } from '@/main/config/config'
import { updateAccessToken, updateBaseUrl, getBaseUrl } from '@/networking/AxiosConfig'
import CookieJar from '@/networking/CookieJar'
import { checkAuthenticationForCurrentRoute } from '@/router'
import AuthTasks from '@/networking/tasks/AuthTasks'
import store from '..'
import { log } from '@/utils/Logger'
import { BackendStatus } from '@/entities/backend/BackendStatus'

export interface AuthState {
    backendStatus: BackendStatus | null
    authenticated: boolean
}

export default {
    namespaced: true as true,
    state: {
        backendStatus: null,
        authenticated: false,
    } as AuthState,
    getters: {
        baseUrl: () => getBaseUrl(),
        backendStatus: (state: AuthState) => state.backendStatus,
        isConnectedToBackend: (state: AuthState) => state.backendStatus != null,
        backendIsVirgin: (state: AuthState) => state.backendStatus?.isVirgin || false,
        isAuthenticated: (state: AuthState) => state.authenticated,
    },
    mutations: {
        setBackendStatus(state: AuthState, status: BackendStatus | null) {
            const isNewStatus = state.backendStatus != status
            state.backendStatus = status
            if (isNewStatus) {
                log(
                    `The status connectedToBackend has changed to ${store.getters.auth.isConnectedToBackend} in auth store.`
                )
                checkAuthenticationForCurrentRoute()
            }
        },
        setAuthenticated(state: AuthState, authenticated: boolean) {
            const newAuthStatus = authenticated != state.authenticated
            state.authenticated = authenticated
            if (newAuthStatus) {
                log(`The status authenticated has changed to ${authenticated} in auth store.`)
                checkAuthenticationForCurrentRoute()
            }
        },
        setApiBaseUrl(state: AuthState, baseUrl: string) {
            updateBaseUrl(baseUrl)
            CookieJar.set(globalconst.cookies.apiBaseUrl, baseUrl)
        },
        setToken(state: AuthState, token: string) {
            updateAccessToken(token)
            CookieJar.set(globalconst.cookies.token, token)
        },
        readCookies() {
            updateAccessToken(CookieJar.get(globalconst.cookies.token))
            updateBaseUrl(CookieJar.get(globalconst.cookies.apiBaseUrl))
        },
        clearToken() {
            updateAccessToken(null)
            CookieJar.remove(globalconst.cookies.token)
        },
        clearApiBaseUrl() {
            updateBaseUrl(null)
            CookieJar.remove(globalconst.cookies.apiBaseUrl)
        },
        clearAll() {
            store.commit.auth.clearToken()
            store.commit.auth.clearApiBaseUrl()
        },
    },
    actions: {
        startup(): Promise<void> {
            log('auth store startup has been triggered, will read cookies and perform status requests')
            store.commit.auth.readCookies()
            return store.dispatch.auth.getStatus().then(connected => {
                return store.dispatch.auth.checkAuthentication().then(authenticated => {
                    log(
                        `startup has finished. Is connected to backend: ${connected}. Is authenticated: ${authenticated}`
                    )
                })
            })
        },

        getStatus(): Promise<boolean> {
            return AuthTasks.status()
                .then(status => {
                    store.commit.auth.setBackendStatus(status)
                    return true
                })
                .catch(() => {
                    store.commit.auth.setBackendStatus(null)

                    // Do a manual disconnect in case the connection was lost in a random moment
                    store.dispatch.auth.disconnectFromBackend()
                    return false
                })
        },
        checkAuthentication(): Promise<boolean> {
            return AuthTasks.checkAuthentication().then(authenticated => {
                store.commit.auth.setAuthenticated(authenticated)
                if (!authenticated) {
                    // Do it manually, because random baseUrl will maybe not return a 401 (automatic logout)
                    store.dispatch.auth.logout()
                }
                return authenticated
            })
        },

        connectToBackend(context: {}, baseUrl: string): Promise<void> {
            store.commit.auth.setApiBaseUrl(baseUrl)
            return store.dispatch.auth.getStatus().then(connected => {
                if (!connected) {
                    throw new Error('Was not able to connect to the provided url.')
                }
            })
        },
        disconnectFromBackend() {
            log('auth store will disconnect from backend and clear all cookies')
            store.commit.auth.clearApiBaseUrl()
            store.commit.auth.setBackendStatus(null)
            store.dispatch.auth.logout()
        },

        login(context: {}, credentials: Credentials): Promise<void> {
            return AuthTasks.login(credentials).then(identity => {
                if (!identity.admin) {
                    throw new Error('Error: This user is not an admin.')
                }
                store.commit.auth.setToken(identity.accessToken)
                store.commit.auth.setAuthenticated(true)
            })
        },
        logout() {
            log('auth store will logout user, clear token cookies and wipe the store.')
            store.commit.event.clear()
            store.commit.judge.clear()
            store.commit.user.clear()

            store.commit.auth.clearToken()
            store.commit.auth.setAuthenticated(false)
        },
    },
}
