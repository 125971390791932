import { Event, EventResponse } from '@/entities/event/Event'
import StoreMapper from '@/entities/mappers/StoreMapper'
import axios from 'axios'
import { API } from '../API'

export default class EventTasks {
    static getAll(): Promise<Event[]> {
        return axios.get(API.EVENTS).then(response => {
            const data = response.data as EventResponse[]
            return data.map(event => StoreMapper.mapEvent(event))
        })
    }

    static assignUser(eventId: number, userId: number): Promise<void> {
        return axios.post(API.USERS, null, {
            params: {
                addEventID: eventId,
                userID: userId,
            },
        })
    }

    static removeUser(eventId: number, userId: number): Promise<void> {
        return axios.post(API.USERS, null, {
            params: {
                delEventID: eventId,
                userID: userId,
            },
        })
    }
}
