import { Credentials, CredentialsRequest } from '../auth/Credentials'
import { EditUser, EditUserRequest } from '../user/EditUser'
import { UserRole } from '../user/User'
import { clone } from './Factory'

export default class APIMapper {
    static mapCredentials(credentials: Credentials): CredentialsRequest {
        return clone(credentials)
    }

    static mapEditUser(user: EditUser): EditUserRequest {
        return clone({
            username: user.username,
            password: user.password || undefined,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            isVisitor: user.role == UserRole.VISITOR,
            isAdmin: user.role == UserRole.ADMIN,
        })
    }
}
