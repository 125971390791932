import input from './en/input.json'
import route from './en/route.json'
import menu from './en/menu.json'
import message from './en/message.json'
import user from './en/user.json'
import event from './en/event.json'
import judge from './en/judge.json'
import other from './en/other.json'

const en = {
    input,
    route,
    menu,
    message,
    user,
    event,
    judge,
    other,
}

export default en
