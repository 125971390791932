








import { Route } from '@/router'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class EventLink extends Vue {
    @Prop() eventId!: number
    @Prop() label!: string

    get route() {
        return { name: Route.EVENT_DETAILS, params: { eventId: this.eventId } }
    }
}
