











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ClipboardButton extends Vue {
    @Prop() copy?: string
    @Prop() size?: string
    @Prop() variant?: string
    @Prop() disabled?: boolean

    get icon() {
        return this.showSuccess ? 'clipboard-check' : 'clipboard'
    }

    get tooltipText() {
        return this.showSuccess ? 'input.action.clipboard-success' : 'input.action.clipboard'
    }

    showSuccess = false
    timeout: number | undefined = undefined
    click() {
        if (this.copy) {
            navigator.clipboard.writeText(this.copy)
            clearTimeout(this.timeout)
            this.showSuccess = true
            this.timeout = setTimeout(() => (this.showSuccess = false), 1500)
        }
    }
}
