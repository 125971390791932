import Vue from 'vue'
import Vuex from 'vuex'
import { createDirectStore } from 'direct-vuex'
import auth from './modules/auth'
import event from './modules/event'
import user from './modules/user'
import judge from './modules/judge'
import message from './modules/message'

Vue.use(Vuex)

const { store, rootActionContext, moduleActionContext } = createDirectStore({
    modules: {
        auth,
        event,
        user,
        judge,
        message,
    },
})

export default store
export { rootActionContext, moduleActionContext }

export type AppStore = typeof store
declare module 'vuex' {
    interface Store<S> {
        store: AppStore
    }
}
