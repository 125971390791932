import { Credentials } from '@/entities/auth/Credentials'
import { Identity, isIdentityResponse, isMeResponse } from '@/entities/auth/Identity'
import { BackendStatus, isBackendStatusResponse } from '@/entities/backend/BackendStatus'
import APIMapper from '@/entities/mappers/APIMapper'
import StoreMapper from '@/entities/mappers/StoreMapper'
import axios from 'axios'
import { API } from '../API'

export default class AuthTasks {
    static login(credentials: Credentials): Promise<Identity> {
        const credentialsRequest = APIMapper.mapCredentials(credentials)
        return axios.post(API.LOGIN, credentialsRequest).then(response => {
            const data = response.data.Body
            if (isIdentityResponse(data)) {
                return StoreMapper.mapIdentity(data)
            } else {
                throw new Error(`The ${API.LOGIN} Endpoint did not return valid response data.`)
            }
        })
    }

    static status(): Promise<BackendStatus> {
        return axios.get(API.STATUS).then(response => {
            const data = response.data.Body
            if (isBackendStatusResponse(data)) {
                return StoreMapper.mapBackendStatus(data)
            } else {
                throw new Error(`The ${API.STATUS} Endpoint did not return valid response data.`)
            }
        })
    }

    static checkAuthentication(): Promise<boolean> {
        return axios
            .get(API.ME)
            .then(response => {
                if (isMeResponse(response.data)) return true
                else return false
            })
            .catch(() => false)
    }
}
