import Vue from 'vue'
import App from './App.vue'
import router from '../router'
import setup from './config/setup'
import store from '@/store'
import i18n from '@/i18n/lang'

setup()

store.dispatch.auth.startup().then(() => {
    new Vue({
        router,
        i18n,
        render: h => h(App),
    }).$mount('#app')
})
