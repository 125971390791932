






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class RawMotionLogo extends Vue {
    @Prop() to?: string

    click() {
        if (this.to) {
            this.$router.push({ name: this.to })
        }
    }
}
