






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NavBarButton extends Vue {
    @Prop() variant?: string
    @Prop() disabled?: boolean

    click() {
        this.$emit('click')
    }
}
