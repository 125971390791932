import { EditUser } from '@/entities/user/EditUser'
import { User } from '@/entities/user/User'
import UserTasks from '@/networking/tasks/UserTasks'
import store from '..'

export interface UserState {
    users: User[]
}

export default {
    namespaced: true as true,
    state: {
        users: [],
    } as UserState,
    getters: {
        users: (state: UserState) => state.users,
        userWithId: (state: UserState) => (id: number | null) => {
            const filtered = state.users.filter(user => user.id == id)
            return filtered.length > 0 ? filtered[0] : null
        },
        userWithJudgeId: (state: UserState) => (id: number) => {
            const filtered = state.users.filter(user => user.judge?.id == id)
            return filtered.length > 0 ? filtered[0] : null
        },
        usersAssignedToEventId: (state: UserState) => (id: number) => {
            return state.users.filter(user => user.events.filter(event => event.id == id).length > 0)
        },
    },
    mutations: {
        setUsers(state: UserState, users: User[]) {
            state.users = users
            store.commit.user.sort()
        },
        sort(state: UserState) {
            state.users.sort((a, b) => b.id - a.id)
        },
        clear(state: UserState) {
            state.users = []
        },
    },
    actions: {
        fetchUsers(): Promise<void> {
            return UserTasks.getAll().then(users => store.commit.user.setUsers(users))
        },
        saveUser(context: {}, user: EditUser): Promise<number> {
            return UserTasks.sendUser(user).then(id => {
                store.dispatch.user.fetchUsers()
                return id
            })
        },
        kickstartWithUser(context: {}, user: EditUser): Promise<number> {
            return UserTasks.sendUser(user, true).then(id => {
                store.dispatch.auth.getStatus()
                return id
            })
        },
        deleteUserWithId(context: {}, userId: number): Promise<void> {
            return UserTasks.deleteUser(userId).then(() => {
                store.dispatch.user.fetchUsers()
            })
        },
        manageEvents(context: {}, payload: { userId: number; newEventIds: number[] }): Promise<void> {
            const user = store.getters.user.userWithId(payload.userId)
            if (user == null) {
                return Promise.reject(`User with id ${payload.userId} not found, while trying to manage events.`)
            }

            const userEventIds = user.events.map(event => event.id)
            const addEventIds = payload.newEventIds.filter(eventId => !userEventIds.includes(eventId))
            const removeEventIds = userEventIds.filter(eventId => !payload.newEventIds.includes(eventId))

            const addEventsTasks = addEventIds.map(eventId =>
                store.dispatch.event.assignUser({ eventId: eventId, userId: payload.userId, noreload: true })
            )
            const removeEventsTasks = removeEventIds.map(eventId =>
                store.dispatch.event.removeUser({ eventId: eventId, userId: payload.userId, noreload: true })
            )

            return Promise.all([...addEventsTasks, ...removeEventsTasks]).then(() => {
                store.dispatch.user.fetchUsers()
            })
        },
    },
}
