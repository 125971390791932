import { IdentityResponse } from '../auth/Identity'
import { Event, EventContest, EventContestResponse, EventResponse } from '../event/Event'
import { Judge, JudgeResponse } from '../user/Judge'
import { User, UserEvent, UserEventResponse, UserJudge, UserJudgeResponse, UserResponse, UserRole } from '../user/User'
import { clone } from './Factory'
import moment from 'moment'
import { EditUser } from '../user/EditUser'
import { BackendStatus, BackendStatusResponse } from '../backend/BackendStatus'

export default class StoreMapper {
    static mapIdentity(identity: IdentityResponse): IdentityResponse {
        return clone(identity)
    }

    static mapEvent(event: EventResponse): Event {
        return clone({
            id: event.Event_ID,
            name: event.Event_Name,
            contests: event.Contests.map(value => this.mapEventContest(value)),
        })
    }

    static mapEventContest(contest: EventContestResponse): EventContest {
        return clone({
            id: contest.Contest_ID,
            name: contest.Contest_Name,
            parts: contest.ContestParts.length,
        })
    }

    static mapJudge(judge: JudgeResponse): Judge {
        return clone({
            id: judge.judgeID,
            isEnabled: judge.isEnabled,
            isLocked: judge.isLocked,
        })
    }

    static mapUserEvent(event: UserEventResponse): UserEvent {
        return clone({
            id: event.Event_ID,
            name: event.Event_Name,
        })
    }

    static mapUserJudge(judge: UserJudgeResponse): UserJudge {
        return clone({
            id: judge.judgeID,
            isLocked: judge.isLocked,
            expirationDate: moment(judge.expirationDate),
        })
    }

    static mapUser(user: UserResponse): User {
        return clone({
            id: user.userID,
            username: user.username,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            role: this.mapUserRole(user.isVisitor, user.isAdmin),
            judge: user.judge ? this.mapUserJudge(user.judge) : null,
            events: user.events.map(value => this.mapUserEvent(value)),
        })
    }

    static mapEditUserFromUser(user: User): EditUser {
        return clone({
            id: user.id,
            username: user.username,
            password: null,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            role: user.role,
        })
    }

    static mapUserRole(isVisitor: boolean, isAdmin: boolean): UserRole {
        if (isAdmin) return UserRole.ADMIN
        else if (isVisitor) return UserRole.VISITOR
        else return UserRole.JUDGE
    }

    static mapBackendStatus(status: BackendStatusResponse): BackendStatus {
        return clone({
            version: status.Status[0].Version,
            state: status.Status[0].State,
            isVirgin: status.Status[0].Virgin || false,
        })
    }
}
