import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en'
import moment from 'moment'

Vue.use(VueI18n)
moment.locale('en')

const i18n = new VueI18n({
    locale: 'en',
    messages: {
        en,
    },
})

export default i18n

export function trsl(key: string, params?: object): string {
    return i18n.t(key, params) as string
}

export function trslc(key: string, count: number | null, params?: object): string {
    return i18n.tc(key, count == null ? undefined : count, params) as string
}
