










import { Route } from '@/router'
import { Vue, Component } from 'vue-property-decorator'
import RawMotionLogo from './RawmotionLogo.vue'
import NavMenuItem from './NavMenuItem.vue'
import NavMenuBottomCard from './NavMenuBottomCard.vue'

@Component({
    components: {
        NavMenuItem,
        RawMotionLogo,
        NavMenuBottomCard,
    },
})
export default class NavMenu extends Vue {
    Route = Route
}
