import store from '@/store'
import { log } from '@/utils/Logger'
import axios, { AxiosError } from 'axios'
import { BackendErrorResponse } from '@/entities/backend/BackendResponse'

export default function axiosConfig() {
    if (process.env.NODE_ENV == 'development') {
        axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
    }

    axios.interceptors.request.use(request => {
        const stringParams = request.params
            ? Object.entries(request.params).map(entry => ` ${entry[0]}=${entry[1]}`)
            : ''
        log(`Request to ${request.url}${stringParams} has started`)
        return request
    })

    axios.interceptors.response.use(
        response => response,
        (error: AxiosError) => {
            const data = error.response?.data as BackendErrorResponse
            const statusCode = error.response?.status
            if (statusCode == 401) {
                store.dispatch.auth.logout()
            } else if (statusCode && statusCode >= 400 && statusCode < 500) {
                store.commit.message.showToast({ text: data.Header.Status_Message, variant: 'danger' })
            } else {
                store.commit.message.showToast({ textKey: 'error.unkown', variant: 'danger' })
            }
            return Promise.reject(error?.response?.statusText)
        }
    )
}

export function updateAccessToken(token: string | null) {
    axios.defaults.headers = {
        ...axios.defaults.headers,
        Authorization: token ? `Bearer ${token}` : undefined,
    }
}

export function updateBaseUrl(baseUrl: string | null) {
    if (process.env.NODE_ENV == 'development' && !baseUrl) {
        axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
    } else {
        axios.defaults.baseURL = baseUrl || undefined
    }
}

export function getBaseUrl(): string | null {
    return axios.defaults.baseURL || null
}
