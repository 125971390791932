import APIMapper from '@/entities/mappers/APIMapper'
import StoreMapper from '@/entities/mappers/StoreMapper'
import { EditUser, EditUserResponse } from '@/entities/user/EditUser'
import { User, UserResponse } from '@/entities/user/User'
import axios from 'axios'
import { API } from '../API'

export default class UserTasks {
    static getAll(): Promise<User[]> {
        return axios.get(API.USERS).then(response => {
            const data = response.data as UserResponse[]
            return data.map(user => StoreMapper.mapUser(user))
        })
    }

    static sendUser(user: EditUser, kickstart?: boolean): Promise<number> {
        const endpoint = kickstart ? API.KICKSTART : API.USERS
        return axios
            .post(endpoint, APIMapper.mapEditUser(user), {
                params: {
                    updateUserID: kickstart || user.id == null ? undefined : user.id,
                },
            })
            .then(response => (response.data as EditUserResponse).userID)
    }

    static deleteUser(userId: number): Promise<void> {
        return axios.post(API.USERS, null, { params: { removeUserID: userId } })
    }
}
