







import { Component, Vue } from 'vue-property-decorator'
import NavMenu from '@/components/menu/NavMenu.vue'
import { Route, unprotectedRoutes } from '@/router'

@Component({
    components: {
        NavMenu,
    },
})
export default class App extends Vue {
    get isProtectedRoute() {
        return !unprotectedRoutes.includes(this.$route.name as Route)
    }
}
