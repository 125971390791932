















import { trsl } from '@/i18n/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class IconButton extends Vue {
    @Prop() icon!: string
    @Prop() size?: string
    @Prop() variant?: string
    @Prop() block?: boolean
    @Prop() disabled?: boolean
    @Prop() tooltip?: string
    @Prop() iconClass?: string

    get tooltipTitle() {
        return this.tooltip ? trsl(this.tooltip) : undefined
    }

    click() {
        this.$emit('click')
    }
}
