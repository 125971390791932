import { Moment } from 'moment'

export interface User {
    id: number
    username: string

    email: string
    firstname: string
    lastname: string

    role: UserRole

    judge: UserJudge | null
    events: UserEvent[]
}

export enum UserRole {
    ADMIN = 'admin',
    JUDGE = 'judge',
    VISITOR = 'visitor',
}

export interface UserJudge {
    id: number
    isLocked: boolean
    expirationDate: Moment
}

export interface UserEvent {
    id: number
    name: string
}

export interface UserResponse {
    userID: number
    username: string

    email: string
    firstname: string
    lastname: string

    isVisitor: boolean
    isAdmin: boolean

    judge: UserJudgeResponse | null
    events: UserEventResponse[]
}

export interface UserJudgeResponse {
    judgeID: number
    isLocked: boolean
    expirationDate: string
}

export interface UserEventResponse {
    Event_ID: number
    Event_Name: string
}
