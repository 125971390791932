import { cloneDeep, isEmpty as lIsEmpty } from 'lodash'
import { Credentials } from '../auth/Credentials'
import { Event } from '../event/Event'
import { EditUser } from '../user/EditUser'
import { User, UserRole } from '../user/User'

export function clone<T>(object: T): T {
    return cloneDeep(object)
}

export function isEmpty(object: unknown): boolean {
    return lIsEmpty(object)
}

export function createEmptyCredentials(): Credentials {
    return {
        username: '',
        password: '',
    }
}

export function createEmptyEditUser(role: UserRole): EditUser {
    return {
        id: null,
        username: '',
        password: '',
        email: '',
        firstname: '',
        lastname: '',
        role: role,
    }
}

export function createEmptyUser(): User {
    return {
        id: 0,
        username: '',
        email: '',
        firstname: '',
        lastname: '',
        role: UserRole.VISITOR,
        judge: null,
        events: [],
    }
}

export function createEmptyEvent(): Event {
    return {
        id: 0,
        name: '',
        contests: [],
    }
}
