














import { trsl, trslc } from '@/i18n/lang'
import moment, { Moment } from 'moment'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReloadInfo extends Vue {
    @Prop() lastUpdate?: Moment

    get text() {
        if (this.lastUpdate) {
            const seconds = this.currentTime.diff(this.lastUpdate, 'seconds')
            return trslc('other.update-info.last-update', seconds)
        } else {
            return trsl('other.update-info.no-last-update')
        }
    }

    isRotating = false

    mounted() {
        this.updateTime()
    }

    destroyed() {
        clearTimeout(this.timeout)
    }

    currentTime = moment()
    timeout: number | undefined = undefined
    updateTime() {
        this.currentTime = moment()
        this.timeout = setTimeout(() => this.updateTime(), 1000)
    }

    click() {
        this.isRotating = false
        this.$emit('update')
        setTimeout(() => {
            this.isRotating = true
        }, 10)
    }
}
