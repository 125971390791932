import '@/style/style.scss'
import VueSweetalert2 from 'vue-sweetalert2'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueCookies from 'vue-cookies'

import axiosConfig from '@/networking/AxiosConfig'
import initValidation from './validation'
import { addIconsToLibrary } from './icons'

import TextInput from '@/components/shared/TextInput.vue'
import IconButton from '@/components/shared/IconButton.vue'
import ClipboardButton from '@/components/shared/ClipboardButton.vue'
import NavBar from '@/components/shared/NavBar.vue'
import NavBarButton from '@/components/shared/NavBarButton.vue'
import UserLink from '@/components/user/UserLink.vue'
import EventLink from '@/components/event/EventLink.vue'
import JudgeLink from '@/components/judge/JudgeLink.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import UpdateInfo from '@/components/shared/UpdateInfo.vue'

function initComponents() {
    Vue.component('TextInput', TextInput)
    Vue.component('IconButton', IconButton)
    Vue.component('FaIcon', FontAwesomeIcon)
    Vue.component('NavBar', NavBar)
    Vue.component('NavBarButton', NavBarButton)
    Vue.component('UserLink', UserLink)
    Vue.component('EventLink', EventLink)
    Vue.component('JudgeLink', JudgeLink)
    Vue.component('ClipboardButton', ClipboardButton)
    Vue.component('UpdateInfo', UpdateInfo)
}

export default function setup() {
    Vue.config.productionTip = false
    Vue.use(BootstrapVue)
    Vue.use(VueCookies)
    Vue.use(VueSweetalert2)

    axiosConfig()
    initValidation()
    initComponents()
    addIconsToLibrary()
}
