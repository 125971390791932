













import { getRouteLabel } from '@/router'
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class NavBar extends Vue {
    @Prop() backTo?: string

    get route() {
        return getRouteLabel(this.$route.name)
    }

    backClicked() {
        this.$router.push({ name: this.backTo })
    }
}
