export interface BackendStatus {
    version: string
    state: string
    isVirgin: boolean
}

export interface BackendStatusResponse {
    Status: {
        State: string
        Version: string
        Virgin?: boolean
    }[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isBackendStatusResponse(object: any): object is BackendStatusResponse {
    return (
        object?.Status != null && Array.isArray(object.Status) && !!object.Status[0].Version && !!object.Status[0].State
    )
}
