




















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TextInput extends Vue {
    // v-model
    @Prop() value!: string

    get model() {
        return this.value
    }

    set model(newValue: string) {
        this.$emit('input', newValue)
    }

    // Rules
    @Prop() noValidation?: boolean
    @Prop() optional?: boolean
    @Prop() rules?: string

    get allRules() {
        return (!this.optional ? 'required|' : '') + (this.rules || '')
    }

    // Other Properties
    @Prop() placeholder?: string
    @Prop() autocomplete?: string
    @Prop() type?: string

    @Prop() vid?: string
    @Prop() list?: string
    @Prop() embed?: boolean

    @Prop() error?: boolean
    @Prop() disabled?: boolean
    @Prop() hidden?: boolean

    // Functions
    @Prop() formatter?: unknown
}
