import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faAlicorn,
    faAngleDoubleRight,
    faArrowToLeft,
    faCalendarCheck,
    faCalendarTimes,
    faCheck,
    faClipboard,
    faClipboardCheck,
    faGavel,
    faHandshake,
    faLockOpen,
    faRedoAlt,
    faSearch,
    faSignOutAlt,
    faSyncAlt,
    faTools,
    faUserTimes,
} from '@fortawesome/pro-regular-svg-icons'

import {
    faUsers as fasUsers,
    faIdBadge as fasIdBadge,
    faCalendarAlt as fasCalenderAlt,
} from '@fortawesome/pro-solid-svg-icons'

export function addIconsToLibrary() {
    library.add(
        fasUsers,
        fasIdBadge,
        fasCalenderAlt,
        faAlicorn,
        faSignOutAlt,
        faArrowToLeft,
        faAngleDoubleRight,
        faSearch,
        faLockOpen,
        faCalendarTimes,
        faCalendarCheck,
        faUserTimes,
        faCheck,
        faClipboard,
        faClipboardCheck,
        faSyncAlt,
        faGavel,
        faTools,
        faHandshake,
        faRedoAlt
    )
}
