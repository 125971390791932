import StoreMapper from '@/entities/mappers/StoreMapper'
import { Judge, JudgeResponse } from '@/entities/user/Judge'
import axios from 'axios'
import { API } from '../API'

export default class JudgeTasks {
    static getAll(): Promise<Judge[]> {
        return axios.get(API.JUDGES).then(response => {
            const data = response.data as JudgeResponse[]
            return data.map(judge => StoreMapper.mapJudge(judge))
        })
    }

    static release(judgeId: number): Promise<void> {
        return axios.post(API.JUDGES, null, { params: { releaseJudgeID: judgeId } })
    }
}
