import { Dialog } from '@/entities/message/Dialog'
import { Toast } from '@/entities/message/Toast'
import { trsl } from '@/i18n/lang'
import Swal from 'sweetalert2'
import store from '..'

function colorForStyle(style: 'success' | 'danger' | 'primary' | 'secondary'): string {
    switch (style) {
        case 'success': {
            return getComputedStyle(document.documentElement).getPropertyValue('--ij-green')
        }
        case 'danger': {
            return getComputedStyle(document.documentElement).getPropertyValue('--ij-red')
        }
        case 'primary': {
            return getComputedStyle(document.documentElement).getPropertyValue('--ij-primary')
        }
        case 'secondary': {
            return getComputedStyle(document.documentElement).getPropertyValue('--ij-secondary')
        }
    }
}

const Toaster = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const Dialoger = Swal.mixin({
    showConfirmButton: true,
    showCancelButton: true,
    reverseButtons: true,
})

export default {
    namespaced: true as true,
    state: {},
    getters: {},
    mutations: {
        showToast(state: {}, toast: Toast) {
            Toaster.fire({
                title: toast.textKey ? trsl(`message.toast.${toast.textKey}`) : toast.text,
                background: colorForStyle(toast.variant),
            })
        },
    },
    actions: {
        showDialog(context: {}, dialog: Dialog): Promise<boolean> {
            return Dialoger.fire({
                title: trsl(`message.dialog.title.${dialog.textKey}`),
                text: trsl(`message.dialog.text.${dialog.textKey}`),

                confirmButtonText: trsl(`input.action.${dialog.confirmKey || 'proceed'}`),
                confirmButtonColor: colorForStyle(dialog.confirmVariant || 'primary'),

                cancelButtonText: trsl('input.action.cancel'),
                cancelButtonColor: colorForStyle('secondary'),
            }).then(result => result.isConfirmed)
        },
        showDeleteDialog(context: {}, textKey: string): Promise<boolean> {
            const deleteDialog: Dialog = {
                textKey: textKey,
                confirmKey: 'delete',
                confirmVariant: 'danger',
            }
            return store.dispatch.message.showDialog(deleteDialog)
        },
    },
}
