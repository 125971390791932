








import { Route } from '@/router'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UserLink extends Vue {
    @Prop() userId!: number
    @Prop() label!: string

    get route() {
        return { name: Route.USER_DETAILS, params: { userId: this.userId } }
    }
}
