import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import {
    required,
    alpha,
    alpha_spaces as alphaSpaces,
    email,
    numeric,
    digits,
    min,
    max,
    confirmed,
    required_if as requiredIf,
    length,
    max_value as maxValue,
} from 'vee-validate/dist/rules'

import en from '@/i18n/en/validation.json'

export default function initValidation() {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    // Vee-Validate Rules
    extend('required', required)
    extend('required_if', requiredIf)
    extend('alpha', alpha)
    extend('alpha_spaces', alphaSpaces)
    extend('email', email)
    extend('numeric', numeric)
    extend('digits', digits)
    extend('min', min)
    extend('max', max)
    extend('confirmed', confirmed)
    extend('length', length)
    extend('max_value', maxValue)

    localize({ en })
    localize('en')
}
