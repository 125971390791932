import { Judge } from '@/entities/user/Judge'
import JudgeTasks from '@/networking/tasks/JudgeTasks'
import moment, { Moment } from 'moment'
import store from '..'

export interface JudgeState {
    judges: Judge[]
    lastFetch: Moment | null
}

let currentTimeout: number | undefined = undefined

export default {
    namespaced: true as true,
    state: {
        judges: [],
        lastFetch: null,
    } as JudgeState,
    getters: {
        judges: (state: JudgeState) => state.judges,
        judgeWithId: (state: JudgeState) => (id: number | null) => {
            const filtered = state.judges.filter(judge => judge.id == id)
            return filtered.length > 0 ? filtered[0] : null
        },
        lastFetch: (state: JudgeState) => state.lastFetch,
    },
    mutations: {
        setJudges(state: JudgeState, judges: Judge[]) {
            state.judges = judges
            store.commit.judge.sort()
        },
        updateLastFetchToNow(state: JudgeState) {
            state.lastFetch = moment()
        },
        sort(state: JudgeState) {
            state.judges.sort((a, b) => b.id - a.id)
        },
        clear(state: JudgeState) {
            state.judges = []
        },
    },
    actions: {
        fetchJudges(): Promise<void> {
            return JudgeTasks.getAll().then(judges => {
                store.commit.judge.setJudges(judges)
                store.commit.judge.updateLastFetchToNow()
            })
        },
        fetchJudgesPeriodically(context: {}, interval: number) {
            clearTimeout(currentTimeout)
            store.dispatch.judge.fetchJudges()
            currentTimeout = setTimeout(() => store.dispatch.judge.fetchJudgesPeriodically(interval), interval)
        },
        stopPeriodicFetching() {
            clearTimeout(currentTimeout)
        },
        releaseJudgeWithId(context: {}, judgeId: number) {
            return JudgeTasks.release(judgeId).then(() => {
                store.dispatch.judge.fetchJudges()
            })
        },
    },
}
