import { trsl } from '@/i18n/lang'
import store from '@/store'
import { log } from '@/utils/Logger'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
//import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

export enum Route {
    HOME = 'home',
    LOGIN = 'login',
    EVENTS = 'events',
    EVENT_DETAILS = 'event-details',
    USERS = 'users',
    USER_DETAILS = 'user-details',
    JUDGES = 'judges',
}

export const unprotectedRoutes = [Route.LOGIN]

const defaultAuthenticatedRoute = Route.EVENTS
const defaultUnauthenticatedRoute = Route.LOGIN

const routes: Array<RouteConfig> = [
    {
        name: Route.HOME,
        path: '/',
        redirect: { name: defaultAuthenticatedRoute },
        //component: HomeView,
    },
    {
        name: Route.LOGIN,
        path: '/login',
        component: () => import('@/views/LoginView.vue'),
    },
    {
        name: Route.EVENTS,
        path: '/events',
        component: () => import('@/views/EventView.vue'),
    },
    {
        name: Route.EVENT_DETAILS,
        path: '/events/:eventId',
        component: () => import('@/views/EventDetailsView.vue'),
    },
    {
        name: Route.USERS,
        path: '/users',
        component: () => import('@/views/UserView.vue'),
    },
    {
        name: Route.USER_DETAILS,
        path: '/users/:userId',
        component: () => import('@/views/UserDetailsView.vue'),
    },
    {
        name: Route.JUDGES,
        path: '/judges',
        component: () => import('@/views/JudgeView.vue'),
    },
    {
        path: '*',
        redirect: '/',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router

function getAlternativeRouteIfNotAllowed(route: string): Route | undefined {
    const isAuthenticated = store.getters.auth.isConnectedToBackend && store.getters.auth.isAuthenticated
    let newRoute: Route | undefined
    if (unprotectedRoutes.includes(route as Route)) {
        newRoute = isAuthenticated ? defaultAuthenticatedRoute : undefined
    } else {
        newRoute = isAuthenticated ? undefined : defaultUnauthenticatedRoute
    }

    if (newRoute) {
        log(`Current route «${route}» is not allowed in current auth-state. Will redirect to «${newRoute}».`)
    }
    return newRoute
}

router.beforeEach((to, from, next) => {
    log(`Router has detected a route change from «${from.name}» to «${to.name}»`)
    if (to.name) next(getAlternativeRouteIfNotAllowed(to.name))
    else next()
})

export function checkAuthenticationForCurrentRoute() {
    const currentRoute = router.currentRoute.name
    if (currentRoute) {
        log(`Router will evaluate current route «${currentRoute}» again.`)
        const alternativeRoute = getAlternativeRouteIfNotAllowed(currentRoute)
        if (alternativeRoute) router.push({ name: alternativeRoute })
    }
}

export function getRouteLabel(route?: string | null) {
    return trsl(`route.${route}`)
}
