export function log(message: unknown, logLevel?: 'debug' | 'info' | 'warn' | 'error') {
    const level = logLevel || 'debug'
    const enabled = process.env.VUE_APP_LOGGING_ENABLED

    if (enabled == 'true') {
        switch (level) {
            case 'debug':
                console.debug(message)
                break
            case 'info':
                console.info(message)
                break
            case 'warn':
                console.warn(message)
                break
            case 'error':
                console.error(message)
                break
        }
    }
}
