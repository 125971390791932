



















import store from '@/store'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class NavMenuBottomCard extends Vue {
    get baseUrl() {
        return store.getters.auth.baseUrl
    }

    logout() {
        store.dispatch.message.showDialog({ textKey: 'logout', confirmKey: 'logout' }).then(confirmed => {
            if (confirmed) store.dispatch.auth.logout()
        })
    }
}
