








import { Route } from '@/router'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UserLink extends Vue {
    @Prop() judgeId!: number
    @Prop() label!: string

    get route() {
        return { name: Route.JUDGES, query: { judgeId: this.judgeId } }
    }
}
