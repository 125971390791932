













import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NavMenuItem extends Vue {
    @Prop() to?: string
    @Prop() name!: string
    @Prop() icon?: string

    click() {
        this.$emit('click')
    }
}
