import Vue from 'vue'

export default class CookieJar {
    static isKey(key: string): boolean {
        return Vue.$cookies.isKey(key)
    }

    static get(key: string): string {
        return Vue.$cookies.get(key)
    }

    static set(key: string, value: string) {
        Vue.$cookies.set(key, value)
    }

    static remove(key: string) {
        Vue.$cookies.remove(key)
    }
}
