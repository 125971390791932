import { Event } from '@/entities/event/Event'
import EventTasks from '@/networking/tasks/EventTasks'
import store from '..'

export interface EventState {
    events: Event[]
}

export default {
    namespaced: true as true,
    state: {
        events: [],
    } as EventState,
    getters: {
        events: (state: EventState) => state.events,
        eventWithId: (state: EventState) => (id: number | null) => {
            const filtered = state.events.filter(event => event.id == id)
            return filtered.length > 0 ? filtered[0] : null
        },
    },
    mutations: {
        setEvents(state: EventState, events: Event[]) {
            state.events = events
            store.commit.event.sort()
        },
        sort(state: EventState) {
            state.events.sort((a, b) => b.id - a.id)
        },
        clear(state: EventState) {
            state.events = []
        },
    },
    actions: {
        fetchEvents(): Promise<void> {
            return EventTasks.getAll().then(events => store.commit.event.setEvents(events))
        },
        assignUser(context: {}, payload: { eventId: number; userId: number; noreload?: boolean }): Promise<void> {
            return EventTasks.assignUser(payload.eventId, payload.userId).then(() => {
                if (!payload.noreload) store.dispatch.user.fetchUsers()
            })
        },
        removeUser(context: {}, payload: { eventId: number; userId: number; noreload?: boolean }): Promise<void> {
            return EventTasks.removeUser(payload.eventId, payload.userId).then(() => {
                if (!payload.noreload) store.dispatch.user.fetchUsers()
            })
        },
        manageUsers(context: {}, payload: { eventId: number; newUserIds: number[] }): Promise<void> {
            const eventUserIds = store.getters.user.usersAssignedToEventId(payload.eventId).map(user => user.id)

            const addUserIds = payload.newUserIds.filter(userId => !eventUserIds.includes(userId))
            const removeUserIds = eventUserIds.filter(userId => !payload.newUserIds.includes(userId))

            const addUserTasks = addUserIds.map(userId =>
                store.dispatch.event.assignUser({ eventId: payload.eventId, userId: userId, noreload: true })
            )
            const removeUserTasks = removeUserIds.map(userId =>
                store.dispatch.event.removeUser({ eventId: payload.eventId, userId: userId, noreload: true })
            )

            return Promise.all([...addUserTasks, ...removeUserTasks]).then(() => {
                store.dispatch.user.fetchUsers()
            })
        },
    },
}
