export interface Identity {
    admin: boolean
    visitor: boolean
    accessToken: string
}

export type IdentityResponse = Identity

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIdentityResponse(object: any): object is IdentityResponse {
    return object?.admin != null && object?.visitor != null && !!object?.accessToken
}

export interface MeResponse {
    Oh: 'Hi'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isMeResponse(object: any): object is MeResponse {
    return object.Oh == 'Hi'
}
